import db from '@services/firebase-service';
import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useRef, useState, useContext } from 'react';
import { bubbleConfig, cloudConfig } from '@services/transient-thought.service';
import { stopEngagement, getFeatureByQId } from '@services/youtube-platform.service';
import { getColor } from '@services/utils.service';
import { StreamDataContext } from '@components/context/StreamContext';
import { logToCloudWatch } from '@services/logger.service';

import TransientThoughtBubble from './TransientThoughtBubble';
import TransientThoughtCloud from './TransientThoughtCloud';
import TransientThoughtResult from './TransientThoughtResult';

import { handleEngagement } from '@services/interactions-service';
import useFonts from '@lib/hooks/useFonts';

export const transientThoughtVariants = {
  bubbles: 'bubbles',
  cloud: 'cloud'
};

const rng = (min, max, exclude) => {
  let randomNumber;

  do randomNumber = Math.floor(Math.random() * (max - min) + min);
  while (randomNumber === exclude);

  return randomNumber;
};

const TransientThought = ({
  streamId, currentRosResult, currentRosFeature, colorData, streamSettings, platformType,
  startInteraction, setCurrentFeatureId, streamSetting, interactionType,
  setShowRosModel, moderationModeRef, activeInteractionRef, updateFSMeta,
  setShowNotification, setStartTime, layoutMode
}) => {
  const {
    firestoreUnsub, setFirestoreUnsub
  } = useContext(StreamDataContext);

  const { isRos } = interactionType;
  const ttPlaybackSpeed = streamSetting?.ttPlaybackSpeed || 'default';
  const [master, setMaster] = useState();
  const [slave, setSlave] = useState();
  const [ttId, setttId] = useState(currentRosFeature.id);
  const points = ['top', 'bottom', 'center'];
  const randomNumber = rng(0, 3);
  const [prevPoint, setPrevPoint] = useState(randomNumber);
  const [featureIdUpdated, setFeatureIdUpdated] = useState(false);
  const [prevBubble, setPrevBubble] = useState('slave');
  const [isCommentsEmpty, setIsCommentsEmpty] = useState(true);
  const [readingStartTime, setReadingStartTime] = useState(null);
  const [readingTime, setReadingTime] = useState();
  const comments = useRef([]);
  const [previousData, setPreviousData] = useState([]);
  const [colors, setColors] = useState(currentRosFeature.color);
  const [settings, setSettings] = useState(currentRosFeature.setting);
  const counter = useRef(0);

  const containerRef = useRef(null);
  const [containerSize, setContainerSize] = useState(null);

  const { getFontStyle } = useFonts();
  const { fontFamily } = getFontStyle(settings?.FontStyle || 'Lobster');

  const animationSpeedMap = {
    slower: { start: 2.5, end: 2 },
    default: { start: 2, end: 1 },
    faster: { start: 1, end: 0.5 }
  };
  const animationSpeed = animationSpeedMap[ttPlaybackSpeed];

  const shrinkAnimationConfig = {
    animate: {
      scale: 1, opacity: 0, x: 0, y: 0
    },
    transition: { duration: animationSpeed.end }
  };

  const readTimeDivisor = { slower: 4, default: 5, faster: 6 };

  const variants = {
    visible: (delay) => ({
      pathLength: 1.2,
      transition: {
        duration: readingTime,
        delay: delay + animationSpeed.start,
        ease: 'easeOut'
      }
    })
  };

  const getNextBubble = (str) => {
    let nextPoint;

    if (
      currentRosFeature.setting.transientThoughtDisplayStyle === transientThoughtVariants.bubbles
    ) {
      nextPoint = rng(0, 3, prevPoint);
    } else {
      // only top and bottom positions are possible for Transient Thought cloud
      nextPoint = rng(0, 2, -1);
    }
    setPrevPoint(nextPoint);
    return `${points[nextPoint]}${str}`;
  };

  const setupTransientThought = ({
    id, settings, colors
  }) => {
    comments.current = [];
    comments.current.length = 0;

    setCurrentFeatureId(id);
    setttId(id);
    setColors(colors);
    setSettings(settings);

    if (currentRosResult.length > 0 && currentRosResult[0].comments.length > 0) {
      setPreviousData(currentRosResult[0].comments);
      setFeatureIdUpdated(false);
      return;
    }

    setPreviousData(currentRosFeature?.comments || []);
    setFeatureIdUpdated(true);
  };

  const handleTransientThought = async ({ interactionId = null }) => {
    const response = await handleEngagement({ streamId, platformType, currentRosFeature, interactionId });

    if (response.status && response.entity) {
      setupTransientThought({
        id: response.entity.engagementId,
        settings: currentRosFeature.setting,
        colors: currentRosFeature.color,
        prevData: []
      });
      updateFSMeta({ activeInteraction: { id: response.entity.engagementId, type: 'transientThought' } });
    }
  };

  useEffect(() => {
    if (activeInteractionRef.current && activeInteractionRef.current?.type === 'transientThought') {
      setupTransientThought({
        id: activeInteractionRef.current.id,
        settings: activeInteractionRef.current.settings,
        colors: activeInteractionRef.current.colors,
        prevData: []
      });
    }
  }, [activeInteractionRef.current]);

  const unsubscribeFromFirestore = () => {
    if (firestoreUnsub.unsub) {
      firestoreUnsub.unsub();
    }
  };

  const stopTransientThought = async () => {
    unsubscribeFromFirestore();
    const sessionId = localStorage.getItem('sessionId');
    await stopEngagement(streamId, 'transientThought', { engagementId: ttId, platformType, sessionId });

    const response = await getFeatureByQId(currentRosFeature.id, streamId, 'transientThought');
    if (response.entity.length > 0 && response.entity[0].comments.length > 0) {
      setPreviousData(response.entity[0].comments);
      return;
    }
    setPreviousData([]);
  };

  const handleStartInteraction = () => {
    if (currentRosResult && currentRosResult.length > 0) {
      handleTransientThought({ interactionId: currentRosResult[0].id });
    } else {
      handleTransientThought({ interactionId: null });
    }
    setStartTime(Date.now());
  };

  const manageTransientThoughtInteraction = () => {
    if (startInteraction && !moderationModeRef.current) {
      handleStartInteraction();
    } else if (ttId) {
      stopTransientThought();
    }
  };

  useEffect(() => {
    if (!currentRosFeature.id) return;

    setShowRosModel(false);
    manageTransientThoughtInteraction();
  }, [currentRosFeature.id, startInteraction]);

  const getNextConfigObject = (bubbleSide, delay) => {
    const comment = comments.current.shift();
    const words = comment.words.split(' ').length;
    const commentReadTime = Math.max(words / readTimeDivisor[ttPlaybackSpeed], 1);
    setReadingTime(commentReadTime);
    const transition = { duration: animationSpeed.start };
    const nextBubble = getNextBubble(bubbleSide);
    const currentCount = counter.current;
    counter.current += 1;

    let config;

    if (
      currentRosFeature.setting.transientThoughtDisplayStyle === transientThoughtVariants.bubbles
    ) {
      config = bubbleConfig[nextBubble];
    } else {
      // only top and bottom positions are possible for Transient Thought cloud
      config = cloudConfig[nextBubble];
    }
    return {
      ...config,
      words: comment.words,
      limit: comment.limit,
      username: comment.username,
      transition: delay ? { ...transition, delay } : transition,
      fillColor: getColor(colors, currentCount, currentRosFeature.color.ttBgColor)
    };
  };

  const updateSlave = (delay) => {
    if (!comments.current.length && !featureIdUpdated) { setIsCommentsEmpty(true); return; }
    setSlave(getNextConfigObject('Left', delay));
  };

  const updateMaster = (delay) => {
    if (!comments.current.length && !featureIdUpdated) { setIsCommentsEmpty(true); return; }
    setMaster(getNextConfigObject('Right', delay));
  };

  const handleEmptyComments = () => {
    setReadingStartTime(new Date().getTime());
    setIsCommentsEmpty(true);
  };

  const initiateMasterAnimation = () => {
    setPrevBubble('master');
    if (comments.current.length) {
      setMaster({ ...master, ...shrinkAnimationConfig });
      return;
    }
    handleEmptyComments();
  };

  const initiateSlaveAnimation = () => {
    setPrevBubble('slave');
    if (comments.current.length) {
      setSlave({ ...slave, ...shrinkAnimationConfig });
      return;
    }
    handleEmptyComments();
  };

  useEffect(() => {
    comments.current = [];
    comments.current.length = 0;
    setMaster(null);
    setSlave(null);
    setIsCommentsEmpty(true);
    if (ttId && isRos) {
      const logData = {
        streamId,
        interactionType: 'transientThought',
        interactionId: ttId
      };
      setFirestoreUnsub({
        unSub: onSnapshot(doc(db, 'streams', streamId, 'transientThought', ttId), (document) => {
          logToCloudWatch('Successfully subscribed to firestore', logData, 'INFO');
          if (document.exists()) {
            setFeatureIdUpdated(false);
            const { commentList } = document.data();

            if (commentList.length > 0) setShowNotification(false);

            if (currentRosFeature?.setting?.showLatestCommentsFirst) {
              comments.current = commentList.reverse();
            } else {
              comments.current = commentList;
            }

            if (isCommentsEmpty) {
              setIsCommentsEmpty(false);
            }
          }
        }, (error) => logToCloudWatch('Failed to subscribe to firestore', { ...logData, error }, 'ERROR'))
      });
    }
    return () => {
      unsubscribeFromFirestore();
    };
  }, [ttId]);

  useEffect(() => {
    if (currentRosResult.length > 0 && currentRosResult[0].comments.length > 0) {
      setPreviousData(currentRosResult[0].comments);
    }
  }, [currentRosResult]);

  const getDelayInfo = () => {
    const timeDifference = readingStartTime
      ? (new Date().getTime() - readingStartTime) / 1000 : readingTime;
    const timeLeftToRead = timeDifference < readingTime;
    return {
      timeDifference, timeLeftToRead
    };
  };

  const initiateAnimation = (state, setState) => {
    const { timeDifference, timeLeftToRead } = getDelayInfo();
    setState({
      ...state,
      animate: {
        scale: 1, opacity: 0, x: 0, y: 0
      },
      transition: timeLeftToRead ? {
        duration: animationSpeed.end,
        delay: timeDifference
      } : { duration: animationSpeed.end }
    });
  };

  const getInitialDelay = () => {
    const { timeDifference, timeLeftToRead } = getDelayInfo();
    return timeLeftToRead ? animationSpeed.end + timeDifference : animationSpeed.end;
  };

  useEffect(() => {
    if (!comments.current.length) return;
    let delay = 0;
    if (master) {
      delay = getInitialDelay();
      initiateAnimation(master, setMaster);
    }
    if (slave) {
      delay = getInitialDelay();
      initiateAnimation(slave, setSlave);
    }
    setReadingStartTime(null);
    if (master || slave) return;

    if (prevBubble === 'slave') {
      updateMaster(delay);
    } else {
      updateSlave(delay);
    }
  }, [isCommentsEmpty, featureIdUpdated]);

  useEffect(() => {
    if (comments.current.length && isCommentsEmpty) { setIsCommentsEmpty(false); }
  }, [comments.current]);

  const disableMaster = ({ opacity }) => {
    if (!opacity) {
      setMaster(null);
      updateSlave();
    }
  };

  const disableSlave = ({ opacity }) => {
    if (!opacity) {
      setSlave(null);
      updateMaster();
    }
  };

  const getWindowSize = ({ width, height }) => {
    if (width >= 1440) {
      if (height >= 900) return 'large';

      return 'medium';
    }

    return 'small';
  };

  useEffect(() => {
    if (!containerRef.current) return;

    setContainerSize(getWindowSize(containerRef.current.getBoundingClientRect()));
  }, []);

  return (
    <div ref={containerRef} className={`w-full h-full relative ${layoutMode === 'square' ? 'scale-75' : ''}`} >
      {
        startInteraction ? <>
          {
            !!master && (currentRosFeature.setting.transientThoughtDisplayStyle
              === transientThoughtVariants.bubbles
              ? <TransientThoughtBubble config={master} disableBubble={disableMaster}
                initiateAnimation={initiateMasterAnimation} variants={variants}
                textColor={colors?.ttmultipleColors ? '#000000' : colors?.ttTextColor1}
                fillColor={colors?.ttmultipleColors ? master?.fillColor : colors?.chatBubble1}
                fontFamily={fontFamily}
              />
              : <TransientThoughtCloud
                containerSize={containerSize}
                transientThoughtShowUsername={currentRosFeature.setting.transientThoughtShowUsername}
                fontFamily={fontFamily}
                config={master} disableBubble={disableMaster}
                initiateAnimation={initiateMasterAnimation} variants={variants}
                textColor={colors?.ttmultipleColors ? '#000000' : colors?.ttTextColor1}
                fillColor={colors?.ttmultipleColors ? master?.fillColor : colors?.chatBubble1} />
            )
          }
          {
            !!slave && (currentRosFeature.setting.transientThoughtDisplayStyle
              === transientThoughtVariants.bubbles
              ? <TransientThoughtBubble config={slave} disableBubble={disableSlave}
                initiateAnimation={initiateSlaveAnimation} variants={variants}
                textColor={colors?.ttmultipleColors ? '#000000' : colors?.ttTextColor2}
                fillColor={colors?.ttmultipleColors ? slave?.fillColor : colors?.chatBubble2}
                fontFamily={fontFamily}
              />
              : <TransientThoughtCloud
                containerSize={containerSize}
                transientThoughtShowUsername={currentRosFeature.setting.transientThoughtShowUsername}
                fontFamily={fontFamily}
                config={slave} disableBubble={disableSlave}
                initiateAnimation={initiateSlaveAnimation} variants={variants}
                textColor={colors?.ttmultipleColors ? '#000000' : colors?.ttTextColor2}
                fillColor={colors?.ttmultipleColors ? slave?.fillColor : colors?.chatBubble2} />)
          }
        </> :
          <TransientThoughtResult
            data={previousData}
            settings={settings}
            colors={colors}
            fontFamily={fontFamily}
          />
      }
    </div >
  );
};

export default TransientThought;
